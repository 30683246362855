<template>
  <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="form"
      :label-width="labelWidth"
      :style="style"
      :disabled="isDisabled === 'false'? false:true">
    <el-form-item label="사업명" prop="title">
      <el-input v-model="form.title"/>
    </el-form-item>
    <el-form-item label="기준년도" prop="stanYr">
      <el-input v-model="form.stanYr"/>
    </el-form-item>
    <el-form-item label="고객사(국문)" prop="org_kor">
      <el-input v-model="form.org_kor"/>
    </el-form-item>
    <el-form-item label="고객사(영문)" prop="org_eng">
      <el-input v-model="form.org_eng"/>
    </el-form-item>
    <el-form-item label="사업비" prop="budget">
      <el-input v-model="form.budget"/>
    </el-form-item>
    <el-form-item label="사업기간" prop="dateRange">
      <el-date-picker
          v-model="form.dateRange"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
      />
    </el-form-item>
    <el-form-item label="업무장소" prop="biz_place_div">
      <el-select v-model="form.biz_place_div" placeholder="please select Zone type">
        <el-option label="비상주" value="비상주"/>
        <el-option label="상주" value="상주"/>
      </el-select>
    </el-form-item>
    <el-form-item label="비고">
      <el-input v-model="form.desc" type="textarea"/>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit(ruleFormRef)">Save</el-button>
      <el-button @click=onCancel>Cancel</el-button>
      <el-button v-if="form.id" style="margin-left: 50px" type="danger" @click="onDelete">Delete</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref} from 'vue'
import dayjs from "dayjs";
import { db } from "src2/thirdparty/firebase/init";
import {collection, doc, getDoc, deleteDoc, setDoc, updateDoc} from "firebase/firestore";

const emit = defineEmits(['afterSaving', 'onCancel', 'afterDelete']);

const props = defineProps({
  style: String,
  labelWidth: String,
  isDisabled: Boolean,
  data: undefined,
});
const projectData = ref(props.data);
const ruleFormRef = ref(null);
const form = reactive({
  id: '',
  title: '',
  stanYr: '',
  org_kor: '',
  org_eng: '',
  budget: 0,
  dateRange: [],
  biz_place_div: '',
  period_st_dt: '',
  period_ed_dt: '',
  members: {},
});

const rules = reactive({
  title: [
    {required: true, message: 'Please input a title', trigger: 'blur'},
    {min: 1, max: 300, message: 'Length should be 3 more', trigger: 'blur'}
  ],
  stanYr: [
    {required: true, message: 'Please select a standard year', trigger: 'blur'},
    {min: 4, max: 4, message: 'Length should be 4', trigger: 'blur'}
  ],
  org_kor: [
    {required: true, message: 'Please input Customer(KR)', trigger: 'blur'}
  ],
  org_eng: [
    {required: true, message: 'Please input Customer(EN)', trigger: 'blur'}
  ],
  biz_place_div: [
    {required: true, message: 'Please select a place where working on', trigger: 'blur'}
  ],
  dateRange: [
    {required: true, message: 'Please select Date', trigger: 'blur'}
  ],
});

onMounted(() => {
  console.log('projectData', projectData);
  console.log('data', props.data);
  if(projectData){
    Object.assign(form, projectData.value);
    if(projectData.value.dateRange){
      form.dateRange[0] = projectData.value.dateRange[0].toDate()
      form.dateRange[1] = projectData.value.dateRange[1].toDate()
    }
  }
});

const generateRandomString = (num) => {
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < num; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const onCancel = () => {
  emit('onCancel', null);
}
const onDelete = () => {
  form.id = form.id.trim();
  const project = collection(db, 'project');
  const document = doc(project, form.id);
  deleteDoc(document);
  emit('afterDelete', null);
}
const onSubmit = async (_ruleFormRef) => {
  await _ruleFormRef.validate((valid) => {
    if(valid) {
      try {
        form.period_st_dt = dayjs(form.dateRange[0]).format('YYYY-MM-DD');
        form.period_ed_dt = dayjs(form.dateRange[1]).format('YYYY-MM-DD');
        const project = collection(db, 'project');
        if(!form.id) { // create
          const uid = generateRandomString(20);
          form.id = uid;
          const document = doc(project, uid);
          setDoc(document, form);
        } else { // update
          form.id = form.id.trim();
          const document = doc(project, form.id);
          updateDoc(document, form);
        }
      }catch(e){
        console.log('error : ', e.message);
      }finally {
        emit('afterSaving', null);
      }
    } else { }
  });

};

</script>

<style scoped lang="scss">

</style>
