<template>
  <div style="text-align: right; margin-bottom: 10px;">
    <el-button :disabled="!hasUpdateAuth" type="primary" @click="handleAddEmployee">Add Employee</el-button>
  </div>
  <employee-list
      :list="data"
      @onModify="handleModify"
      @onDelete="handleDelete"
      :updateable="hasUpdateAuth"
  ></employee-list>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref, getCurrentInstance} from 'vue'
import EmployeeList from "@/apps/cesto/components/employee/EmployeeList.vue";
import {loginStore} from "@/stores/login.store";

import EmployeeService from "src2/services/employee.service.js";
const session = loginStore();
const data = ref([]);
const hasUpdateAuth = ref(false);
const {proxy} = getCurrentInstance();
onMounted( async () => {
  data.value = await EmployeeService.getList();

  console.log(session.data.auth);
  if(session.data.auth.includes('manager') || session.data.auth.includes('admin')) {
    hasUpdateAuth.value = true;
  }
});

const handleAddEmployee = () => {
  proxy.$router.push('/emp/create');
}

const handleModify = (id) => {
  console.log('handleModify', id);
  proxy.$router.push({name: 'Employee Modify', params: {'id': id}});
}

const handleDelete = (id) => {
  console.log('handleDelete', id);
  EmployeeService.delete(id).then(
      async success => {
        data.value = await EmployeeService.getList();
      }
  );
}
</script>

<style scoped lang="scss">

</style>
