<template>
  <el-descriptions
      class="search-area"
      title=""
      :column="1"
      size="default"
      border
  >
    <template #extra>
      <el-button :disabled="!hasUpdateAuth" @click="onCreate" size="small" type="primary">Create Project</el-button>
    </template>
    <el-descriptions-item>
      <template #label>
        <div class="cell-item">
          <el-icon>
            <Calendar/>
          </el-icon>
          기준년도
        </div>
      </template>
      <el-select v-model="targetYear">
        <el-option v-for="year in yearList" :label="year" :value="year"/>
      </el-select>
    </el-descriptions-item>
  </el-descriptions>
  <div class="project-list-container">
    <ProjectList
        :target-year="targetYear"
        @onDetail="moveToDetail"
        :updatable="hasUpdateAuth"
    ></ProjectList>
  </div>
</template>

<script setup>
import {defineProps, getCurrentInstance, defineEmits, onMounted, reactive, ref, computed} from 'vue'
import ProjectList from "@/apps/cesto/components/project/ProjectList.vue";
import {loginStore} from "@/stores/login.store";

const session = loginStore();
const hasUpdateAuth = ref(false);
const targetYear = ref('');
const {proxy} = getCurrentInstance();
const yearList = computed(() => {
  const arr = [];
  const date = new Date();
  const till = Number(date.getFullYear()) + 1;
  for (let i = 2022; i <= till; i++) {
    arr.push(i.toString());
  }
  return arr;
});
const onCreate = () => {
  proxy.$router.push('/pms/project/create');
}
const moveToDetail = (uid) => {
  console.log('uid', uid);
  proxy.$router.push({name: 'projectDetail', params: {'id': uid}});
}
onMounted(() => {
  targetYear.value = new Date().getFullYear();
  // auth checking
  if(session.data.auth.includes('manager') || session.data.auth.includes('admin')) {
    hasUpdateAuth.value = true;
  }

});

</script>

<style scoped lang="scss">
.el-descriptions {
  margin-top: 20px;
}

.cell-item {
  display: flex;
  align-items: center;
}

.search-area {
  margin: 20px 0px;
}

.project-list-container {
  margin-bottom: 50px;
}
</style>
