<template>
  <el-header v-if="links" style="padding: 0 0px !important; height: 60px;">
    <el-menu
        default-active="0"
        mode="horizontal"
    >
      <template v-for="(item, idx) in links">
        <el-menu-item :index="idx" @click="onLink(item)">{{item.title}}</el-menu-item>
      </template>
    </el-menu>
  </el-header>
  <template v-if="Object.keys(selectedObj).length === 0">
    <el-empty :image-size="400" description="Please choose a site on the top"/>
  </template>
  <iframe ref="contentFrame" class="link-container" v-if="selectedObj.url" :src="selectedObj.url"/>
</template>

<script setup>
import {defineProps, defineEmits, onBeforeMount, reactive, ref} from 'vue'

const LINKS = [
  {
    title: 'LOL Colors',
    url: 'https://www.webdesignrankings.com/resources/lolcolors/',
    desc: '배색::4색 예제'
  },
  {
    title: 'haikei',
    url: 'https://app.haikei.app/',
    desc: 'Background Generator'
  },
  {
    title: 'mycolor.space',
    url: 'https://mycolor.space/?hex=%23845EC2&sub=1',
    desc: 'Color Palette sample'
  },
  {
    title: 'KHROMA',
    url: 'http://khroma.co/',
    desc: 'AI 배색 추천'
  },
  {
    title: 'Hello Color',
    url: 'https://jxnblk.github.io/hello-color/?c=d206a2',
    desc: '배색::2색'
  },
  {
    title: 'Random Palate',
    url: 'https://www.threebu.it/random-material-palette',
    desc: '배색::4색'
  },
  {
    title: 'ColorDrop',
    url: 'https://colordrop.io/',
    desc: '배색::4색'
  },
  {
    title: 'UI Gradient',
    url: 'https://webgradients.com/',
    desc: '그라데이션 추천'
  }
]

const contentFrame = ref(null);
const selectedObj = reactive({}); //brand.LINKS[0]
let links = reactive([]);

onBeforeMount(() => {
  links.push(...LINKS);
})
const onLink = (obj) => {
  console.log(obj, contentFrame.src);
  selectedObj.url = obj.url;
}


</script>

<style scoped lang="scss">
.link-container {
  //height: calc(100% - 70px);
  height: calc(100vh - 330px);
  width: 100%;
}
</style>
