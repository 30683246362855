import axiosHandler from "@/utils/axios.handler";
import brand from "@/brand";

const STATIS_URL = `${brand.backend}`;

class YearStatisService {
  getList(params) {
    return axiosHandler
      .getInstance()
      .get(
        `${STATIS_URL}statis/_year`,
        { params: params.request },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        }
      )
      .then((res) => {
        res.data.data.forEach((each, i) => {
          if (i != res.data.data.length - 1) {
            let percent = ((Number(each.cnt) - Number(res.data.data[i + 1].cnt)) / Number(res.data.data[i + 1].cnt)) * 100;
            each.percent = percent.toFixed(2) + "%";
          }
        });

        params.success({
          rowData: res.data.data,
          rowCount: res.data.lastRow,
        });
        return { res, params };
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }
}

export default new YearStatisService();
