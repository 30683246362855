import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBNHRaz7tgIXteLQ4l1EZ7XaP1Khj0Fxsg",
    authDomain: "holdall-aca41.firebaseapp.com",
    projectId: "holdall-aca41",
    storageBucket: "holdall-aca41.appspot.com",
    messagingSenderId: "785790006190",
    appId: "1:785790006190:web:7156a7b221c0dee3748e3f",
    measurementId: "G-8T45BNR8K7"
};

// init firebase
//initializeApp(firebaseConfig)

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// init firestore service
const db = getFirestore()
const auth = getAuth()

export {db, auth, createUserWithEmailAndPassword, signInWithEmailAndPassword}
