<template>

  <el-row v-if="tagNames" style="height:calc(100% - 80px); !important;">

    <div class="snippet-container snippet-group-container">
      <el-card class="card">
        <div class="folder-content">
          <span class="fav-title">Favorites</span>
          <ul>
            <li @click="selectedTag = null" :class="selectedTag === null? 'active':''">
              <el-icon>
                <Folder/>
              </el-icon>
              <div>All Snippets</div>
            </li>
          </ul>
        </div>
        <div class="folder-content">
          <span class="fav-title">Favorites</span>
          <ul>
            <li v-for="each in tagNames" @click="selectedTag = each" :class="selectedTag === each? 'active':''">
              <el-icon>
                <Folder/>
              </el-icon>
              <div>{{ each }}</div>
            </li>
          </ul>
        </div>
      </el-card>
    </div>

    <div class="snippet-container snippet-list-container">
      <div>
        <el-input @keyup="handleSearchKeyup" size="small" placeholder="Type something" :prefix-icon="Search" v-model="searchTxt"></el-input>
      </div>
      <el-card class="card">
        <div>
          <div class="current-tag">{{ selectedTag === null ? 'All Snippets' : selectedTag }}</div>
          <template v-for="row in list">
            <div v-if="selectedTag !==null? row.tag === selectedTag: true" class="snippet" @click="handleOpen(row.id)">
              <div>
                {{ row.title }}
              </div>
              <div class="snippet-info">
                <span class="owner">{{ row.empNm }}</span>
                <span>
                  <el-tag size="small" effect="plain">{{ row.tag }}</el-tag>
                </span>
              </div>
            </div>
          </template>
        </div>
      </el-card>
    </div>

    <div style="width: calc(100% - 410px); height: 100%;">
      <div class="action-container">
        <div>
          <div style="display: flex; cursor: pointer;">
            <el-button type="primary" size="small" :icon="DocumentCopy" circle @click="handleCopy"/>
          </div>
          <div class="txt_right">
            <el-button @click="handleSave" type="primary" size="small" class="ml-10">
              {{ form.id ? 'Update' : 'Create' }}
            </el-button>
            <el-button @click="handleInit" size="small">New</el-button>
          </div>
        </div>
      </div>

      <el-card style="height:calc(100% - 34px); overflow-y: auto;">
        <div style="margin-bottom: 10px;">
          <el-select v-model="form.tag" class="mr-10" size="small" style="width: 150px;" placeholder="Tag">
            <el-option v-for="each in tagNames" :label="each" :value="each"></el-option>
          </el-select>
          <el-select v-model="form.type" class="mr-10" size="small" style="width: 150px;" @change="handleTypeChange" placeholder="Language">
            <el-option v-for="each in types" :label="each" :value="each"></el-option>
          </el-select>
        </div>
        <div class="mb-10">
          <el-input v-model="form.title" placeholder="Please input Title for your snippet" class="mr-10" size="small"></el-input>
        </div>
        <div style="overflow-y: auto">
          <Snippet style="min-height: 300px;"
                   :init-code="form.code"
                   @onChange="handleChange($event)"
                   :type="ediorLang"
          ></Snippet>
        </div>
      </el-card>
    </div>
  </el-row>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref, onBeforeMount} from 'vue'
import {db} from "src2/thirdparty/firebase/init";
import {collection, query, doc, getDoc, where, getDocs, setDoc, updateDoc} from "firebase/firestore";
import Snippet from "@/apps/cesto/components/snippet/Snippet.vue";
import {ElMessage} from 'element-plus'
import CodeService from "@/apps/cesto/services/code.service";
import {
  DocumentCopy,
  Check,
  Search,
} from '@element-plus/icons-vue'
import {loginStore} from "@/stores/login.store";

const ediorLang = ref();
const session = loginStore();
const selectedTag = ref(null);
const orgList = ref();
const list = ref();
const detail = ref();
const tagNames = ref(null);
const searchTxt = ref();
const types = [
    'Javascript',
    'HTML',
    'JAVA',
    'Markdown',
];
const form = reactive({
  id: '',
  code: '',
  tag: '',
  title: '',
  isShared: true,
  empId: '',
  empNm: '',
  delYn: 'N',
  type: '',
});

const formFrame = {
  id: '',
  code: '',
  tag: '',
  title: '',
  isShared: true,
  empId: '',
  empNm: '',
  delYn: 'N',
  type: '',
}

onBeforeMount(async () => {
  console.log(session.data);
})

onMounted(async () => {
  // https://www.npmjs.com/package/vue-codemirror
  const data = await getList();
  list.value = data;
  orgList.value = data;
  const codeData = await CodeService.getTargetCodeList('C001');
  tagNames.value = codeData.tag;
});
const handleInit = () => {
  Object.assign(form, formFrame);
}
const handleCopy = () => {
  window.navigator.clipboard.writeText(form.code).then(() => {
    ElMessage('Copied your code to Clipboard!');
  });
}

const handleSearchKeyup = (e) => {
  if(list.value) {
    if(searchTxt.value !== ''){
      list.value = orgList.value.filter(r => r.title.indexOf(searchTxt.value) > -1 || r.code.indexOf(searchTxt.value) > -1);
    } else {
      list.value = orgList.value;
    }
  }
}

const handleTypeChange = (e) => {
  form.type, ediorLang.value = e.value;
}

const handleSave = () => {
  const snippetCollection = collection(db, 'snippet');

  if (form.tag === '') {
    ElMessage.error('Tag를 선택하세요.');
    return;
  } else if (form.type === '') {
    ElMessage.error('언어를 선택하세요.');
    return;
  } else if (form.title === '') {
    ElMessage.error('스니펫명을 입력하세요.');
    return;
  } else if (form.code === '') {
    ElMessage.error('코드를 작성하세요.');
    return;
  }

  if (!form.id) { // create
    const uid = generateRandomString(20);
    form.id = uid;
    form.empId = session.data.id;
    form.empNm = session.data.name;
    const document = doc(snippetCollection, uid);
    setDoc(document, form);
    const _temp = {};
    Object.assign(_temp, form);
    list.value.push(_temp);
    ElMessage('Saved your snippet!');
  } else { // update
    const document = doc(snippetCollection, form.id);
    updateDoc(document, form);
    list.value = list.value.filter(r => r.id !== form.id);
    const _temp = {};
    Object.assign(_temp, form);
    list.value.push(_temp);
    ElMessage('Updated your snippet!');
  }
}

const handleChange = (e) => {
  form.code = e;
}

const handleOpen = (id) => {
  const arr = Array.of(...list.value);
  detail.value = arr.filter(r => r.id === id)[0];
  Object.assign(form, detail.value);
  form.id = form.id.trim();
  ediorLang.vaue = form.type;
  console.log(form);
  console.log(detail.value);
}

const getList = async () => {
  const snippetCollection = collection(db, 'snippet');
  const q = query(snippetCollection, where("delYn", "==", 'N'));
  const ret = await getDocs(q);
  const data = [];
  ret.docs.map(r => {
    data.push(r.data());
  });
  return data;
}

const generateRandomString = (num) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < num; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

</script>

<style scoped lang="scss">
.snippet {
  margin-bottom: 5px;
  padding: 5px;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  font-weight: 600;

  div:first-child {
    color: #5e5e5e;
    padding-bottom: 3px;
  }

  div {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.current-tag {
  padding: 5px;
  font-weight: 600;
  background: #263957;
  color: white;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.snippet-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .owner {
    font-size: 0.9em;
    color: #888888;
    font-style: italic;
  }
}

.snippet-container {
  font-size: 13px;
  height: 100%;
  margin-right: 10px;

  .card:nth-child(1) {
    height: calc(100%);
  }

  .card:nth-child(2) {
    height: calc(100% - 24px);
    overflow-y: auto;
  }
}

.snippet-group-container {
  width: 150px;
}

.snippet-list-container {
  width: 230px;
}

.folder-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  ul {
    padding-left: 10px;
  }

  ul > li {
    margin-top: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  ul > li.active {
    background: #c1c1f7;
    padding-left: 5px;
    border-radius: 7px;
  }

  ul > li > div {
    font-size: 13px;
    padding-left: 3px;
  }
}

.fav-title {
  color: #757575;
  font-width: bold;
  font-size: 0.7rem;
}

.title_line {
  width: 140px;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.action-container {
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 5px;

  div:first-child {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}
</style>
