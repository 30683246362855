const brandjs = {
  defaultTitle: "MUTO ;)",
  backend: "http://localhost:8080/",
  cookie: {
    expireTimes: "30d",
    path: "/hwfe",
    domain: "hwfecookies",
  },
  ROLE: {
    USER: "AT00000001",
    SYSTEM: "AT00000000",
  },
  ui: {
    layout: {
      frameScroll: "CONTENT", // mode: ALL, CONTENT
      screenSystem: 24,
      sidebar: {
        defaultWidth: '180px',
      },
      screenWidth: 'FULL', // FULL or xxxxpx
      nav: { // 컨텐츠 페이지 위치를 알려주는 Navigation line
        show: true,
        underline: {
          style: 'solid', // solid, dotted, none
          color: '#d5d5d5',
          paddingBottom: '10px'
        }
      },
      /** If you want to use 'Default Menus' providing this framework. **/
      menu: {
        activeTextColor: '#ffd04b',
        backgroundColor: '#263857',
        textColor: '#fff',
        className: 'none' // this name should be defined in CSS file.
      }
    },
  },
};

brandjs.ui.layout.screenDefaultSystem = brandjs.ui.layout.screenSystem;
brandjs.ui.layout.screenDefaultWidth = brandjs.ui.layout.screenWidth;
brandjs.ui.layout.frameDefaultScroll = brandjs.ui.layout.frameScroll;

export default brandjs;
