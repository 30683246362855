<template>
  <el-table
      ref="multipleTableRef"
      :data="data"
      style="width: 100%"
      table-layout="auto"
      @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="55" />
    <el-table-column type="index" width="50"/>
    <el-table-column prop="name" label="이름" width="100"/>
    <el-table-column prop="place" label="근무장소" width="70"/>
    <el-table-column prop="grade" label="등급" width="70"/>
    <el-table-column prop="role" label="역할" width=""/>
  </el-table>
  <div style="margin-top: 20px; text-align: right;">
    <el-button @click="handleEmpSave">Save</el-button>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref, watchEffect, onUpdated} from 'vue'
import { db } from "src2/thirdparty/firebase/init";
import {collection, doc, getDoc, getDocs, setDoc, updateDoc} from "firebase/firestore";
const data = ref();
const multipleTableRef = ref(null);
const emit = defineEmits(['onSave'])
const props = defineProps({
  selectedData: {}
})
const initData = reactive(props.selectedData);
const selectedList = ref();
const handleEmpSave = () => {
  emit('onSave', selectedList.value);
}

const handleSelectionChange = (val) => {
  selectedList.value = val
}

watchEffect(() => {
  //console.log(initData.row, initData.year, initData.month);

});

onUpdated(() => {
  if(data.value && initData.row){
    multipleTableRef.value.clearSelection();
    data.value.map(r => {
      initData.row.members[initData.year][initData.month].forEach(i => {
        if(r.id === i){
          multipleTableRef.value.toggleRowSelection(r, true);
        }
      })
    })
  }
})

onMounted(async () => {
  const emp = collection(db, 'emp');
  const ret = await getDocs(emp);
  data.value = [];
  ret.docs.map(r => {
    data.value.push(r.data());
  });
});

</script>

<style scoped lang="scss">

</style>
