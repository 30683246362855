<template>
  <router-view></router-view>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref} from 'vue'
onMounted(() => {

});

</script>

<style scoped lang="scss">

</style>
