<template>
  <el-table v-if="members" :data="projects" style="width: 100%" table-layout="fixed"
            :header-cell-style="{padding: '0px 0px', 'text-align': 'center'}" :cell-style="cellStyle">
    <el-table-column fixed prop="no" label="" width="40"/>
    <el-table-column fixed prop="stanYr" label="Year" width="60"/>
    <el-table-column fixed prop="title" label="Title" width="200">
      <template #default="props">
        <div class="cell-title" style="height: 100%; vertical-align: top;">
          <p>{{ props.row.title }}</p>
          <span>{{ props.row.org_kor }}</span>
          <span>{{ props.row.period_st_dt }} ~ {{props.row.period_ed_dt }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="year" v-for="year in [Number(searchYr), (Number(searchYr) + 1)]">
      <el-table-column v-for="(month, idx) of monthData" :prop="month" :label="month" width="75">
        <template #default="prop">
          <div
              v-if="
              new Date(`${prop.row.st_yyyy}-${prop.row.st_mm}-01 00:00:00`).getTime() <=
              new Date(`${year}-${month<10?'0'+month:month}-01 00:00:00`).getTime() &&
              new Date(`${prop.row.ed_yyyy}-${prop.row.ed_mm}-30 00:00:00`).getTime() >=
              new Date(`${year}-${month<0?'0'+month:month}-30 00:00:00`).getTime()"
              class="project-period-bar"></div>
          <div
              class="pt-10"
              v-if="
                  prop.row.members[year] &&
                  prop.row.members[year][month] &&
                  prop.row.members[year][month].length > 0"
              @dragenter.prevent
              @dragover.prevent
              @drop.prevent="onDrop($event, idx)"
          >
            <ul>
              <li
                  v-for="member in prop.row.members[year][month]"
                  draggable="true"
                  @dragstart="startDrag($event, getMember(member))"
              >
                <el-popover effect="light" trigger="hover" placement="top" width="auto">
                  <template #default>
                    <div>{{ getMember(member).name }} {{ getMember(member).position }}</div>
                    <div>{{ getMember(member).grade }}</div>
                  </template>
                  <template #reference>
                    <el-tag>{{ getMember(member).name }}</el-tag>
                  </template>
                </el-popover>
              </li>
            </ul>
          </div>
          <div style="margin-top:5px;">
            <el-button :disabled="!updatable" @click="onEmpDialogShow(prop.row, year, month)" size="small" style="width: 51px; height: 18px;">
              <el-icon>
                <Plus/>
              </el-icon>
              Add
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column fixed="right" label="Operations" width="120">
      <template #default="data">
        <el-button :disabled="!updatable" link type="primary" size="small" @click="goDetail(data.row.id)">Detail & Edit</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog v-model="dialogEmpVisible" title="Choose employees">
    <EmpSelection
        @onSave="handleSaveEmp"
        :selectedData="selectedData"
    ></EmpSelection>
  </el-dialog>
</template>

<script setup>
import {defineProps, computed, onMounted, ref, toRef, watchEffect, reactive} from 'vue';
import dayjs from "dayjs";
import { db } from "src2/thirdparty/firebase/init";
import {collection, doc, getDoc, getDocs, query, where, setDoc, updateDoc} from "firebase/firestore";
import EmpSelection from "@/apps/cesto/components/employee/EmpSelection.vue";

const emit = defineEmits(['onDetail'])
const props = defineProps({
  targetYear: String,
  updatable: Boolean
})
const dialogEmpVisible = ref();
const key = ref(null);
const searchYr = toRef(props, 'targetYear');
const projects = ref([]);
const data = ref([]);
const members = ref([]);
const currDate = new Date();
const monthData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const startDrag = (event, item) => {
  console.log(event);
  event.dataTransfer.dropEffect = "move"
  event.dataTransfer.effectAllowed = "move"
  event.dataTransfer.setData("selectedItem", item.id)
}

const onDrop = (event, colNum) => {
  const selectedItem = Number(event.dataTransfer.getData("selectedItem"))
}

  const selectedData = reactive({
    row: null,
    year: '',
    month: ''
  })
  watchEffect(() => {
    searchYr.value;
    projects.value = Array.of(...projects.value);
  })

  const getRowKeys = (row) => {
    key.value = row;
    return row.key;
  };

  const onEmpDialogShow = (row, year, month) => {
    // (row['members'][year][month]);
    if (!row['members'][year])
      row['members'][year] = {};
    if (!row['members'][year][month])
      row['members'][year][month] = [];
    selectedData.row = row;
    selectedData.year = year;
    selectedData.month = month;
    dialogEmpVisible.value = true;
  }

  const handleSaveEmp = (list) => {
    if (list) {
      // console.log(list);
      const ids = list.map(r => r.id);
      selectedData.row['members'][selectedData.year][selectedData.month] = [];
      selectedData.row['members'][selectedData.year][selectedData.month].push(...ids);
      // console.log('selectedData.row', selectedData.row);
      const project = collection(db, 'project');
      const document = doc(project, selectedData.row.id);
      updateDoc(document, selectedData.row);
    }
    dialogEmpVisible.value = false;
  }

  const getMember = (id) => {
    return members.value.filter(r => r.id === id)[0];
  }

  const cellStyle = ({row, column, rowIndex, columnIndex}) => {
    if (columnIndex <= 1) { // ID + year
      return {'text-align': 'center'};
    }
    if (columnIndex > 2 && columnIndex < 27) { // Month
      return {'vertical-align': 'top'};
    }
  }

  const goDetail = (id) => {
    emit('onDetail', id);
  }

  /*const members = [
    {
      id: 1,
      name: '박종진',
      ord: 3,
      place: '본사',
      position: 'CTO',
      grade: '특급'
    },
    {
      id: 2,
      name: '양수아',
      ord: 3,
      place: '본사',
      position: 'CTO',
      grade: '특급'
    }
  ];*/

  const getMembers = async () => {
    const empCollection = collection(db, 'emp');
    const ret = await getDocs(empCollection);
    members.value = [];

    ret.docs.map((r, idx) => {
      const target = r.data();
      target.no = idx + 1;
      members.value.push(target);
    });
  }

  const getData = async () => {
    const searchCondition = [
      props.targetYear + '',
      (Number(props.targetYear) + 1) + ''
    ];
    console.log('searchCondition', searchCondition);
    const projectCollection = collection(db, 'project');
    const q = query(projectCollection, where('stanYr', 'in', searchCondition))
    const ret = await getDocs(q);
    projects.value = [];
    ret.docs.map((r, idx) => {
      const target = r.data();
      target.no = idx + 1;
      projects.value.push(target);
    });
  }

  onMounted(async () => {
    await getMembers();
    await getData();
    /*projects.value = [
      {
        id: '1',
        title: '원료 안전성 예측 시스템 구축',
        stanYr: '2022',
        org_eng: 'kcii',
        org_kor: '대한화장품산업연구원',
        period_st_dt: '2022-02-03',
        period_ed_dt: '2023-03-12',
        priority: '',
        members: {
          2022: {
            6: [1, 2],
            7: [1],
            8: [1],
            9: [1, 2],
          },
          2023: {
            6: [1],
            7: [2],
            8: [2],
            9: [2],
          }
        }
      },
      {
        id: '2',
        title: '원료 안전성 예측 시스템 구축2',
        stanYr: '2023',
        customerId: 'kcii',
        period_st_dt: '2023-01-03',
        period_ed_dt: '2023-4-12',
        priority: '',
        members: {
          2: [],
          3: [1],
          4: [1, 2],
          5: [1],
          6: [],
          7: [],
          8: [],
          9: [],
          10: [],
          11: [],
          12: [],
        }
      }
    ];*/

    projects.value = projects.value.map((r) => {
      const st_yyyy = dayjs(new Date(`${r.period_st_dt} 00:00:00`)).format('YYYY');
      const ed_yyyy = dayjs(new Date(`${r.period_ed_dt} 00:00:00`)).format('YYYY');
      const st_mm = dayjs(new Date(`${r.period_st_dt} 00:00:00`)).format('MM');
      const ed_mm = dayjs(new Date(`${r.period_ed_dt} 00:00:00`)).format('MM');
      r.st_yyyy = st_yyyy;
      r.ed_yyyy = ed_yyyy;
      r.st_mm = st_mm;
      r.ed_mm = ed_mm;
      return r;
    });

  });
// TODO: get project list from firebase
/*
json structure
[
  {
    id: '', unique id for each project
    title: '',
    stan_yn: '', // YYYY
    customerId: '',
    period_st_dt: '', // YYYY-MM-DD
    period+ed_dt: '', // YYYY-MM-DD
    priority: '', // 우선순위 / 1 ~
    members: [
      {
        mid: '',
        div: '', // 참여구분 /  PM, PMO, PL, DP, TS, AA
        st_dt: '',
        ed_dt: ''
      }
    ]
  }
]
 */
</script>

<style scoped lang="scss">
.cell-title span {
  font-size: 0.8rem; color: #b2b2b2; display: block;
}
.project-period-bar {
  width: 100%;
  margin: 0 auto;
  background-color: #39c92c;
  height: 5px;
  left: -0.1px;
  position: absolute;
}

</style>
