<template>
  <Project
      v-if="data"
      style="max-width: 600px"
      label-width="140px"
      is-disabled="false"
      @afterSaving="afterSaving"
      @onCancel="onCancel"
      @afterDelete="handleAfterDelete"
      :data="data"
  ></Project>
</template>

<script setup>
import {defineProps, getCurrentInstance, defineEmits, onMounted, reactive, ref} from 'vue'
import Project from "src2/components/project/Project.vue";
import { db } from "src2/thirdparty/firebase/init";
import {collection, doc, getDoc} from "firebase/firestore";

const { proxy } = getCurrentInstance();
const data = ref(null);
const afterSaving = () => {
  moveToPMS();
}

const onCancel = () => {
  moveToPMS();
}

const handleAfterDelete = () => {
  moveToPMS();
}

const moveToPMS = () => {
  proxy.$router.push('/pms/prjWithMpChart');
}

onMounted(async () => {
  console.log('proxy.$route.params', proxy.$route);
  const id = proxy.$route.params.id;
  if(id){
    const projectCollection = collection(db, 'project');
    const document = await doc(projectCollection, id);
    const d = await getDoc(document);
    console.log(d.data());
    data.value = d.data();
  } else {
    data.value = {};
  }
});

</script>

<style scoped lang="scss">

</style>
