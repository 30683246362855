<template>
  <el-menu
    :active-text-color="activeTextColor"
    :background-color="backgroundColor"
    :text-color="textColor"
    :default-active="defaultActive"
    :class="className"
    :mode="mode"
    :collapse="isCollapse"
  >
    <slot></slot>
  </el-menu>
</template>

<script setup>
import { ref, watchEffect, defineProps } from "vue";
import { Document, Menu as IconMenu, Location, Setting } from "@element-plus/icons-vue";
import { brandStore } from "@/stores/brand.store";
const emit = defineEmits(["update:sidebarSize"]);
const brand = brandStore();
const isCollapse = ref(false);
const appWidth = ref();
const activeTextColor = ref(brand.data.ui.layout.menu.activeTextColor);
const backgroundColor = ref(brand.data.ui.layout.menu.backgroundColor);
const textColor = ref(brand.data.ui.layout.menu.textColor);
const className = ref(brand.data.ui.layout.menu.className);

const props = defineProps({
  defaultActive: String,
  mode: String, // horizontal or
});

const getAppWidth = () => {
  appWidth.value = document.querySelector("#app");
};

window.addEventListener("resize", function () {
  getAppWidth();
  if (appWidth.value.getBoundingClientRect().width <= 767) {
    isCollapse.value = true;
    console.log(isCollapse.value);
    emit("update:sidebarSize", 63);
  } else {
    isCollapse.value = false;
    emit("update:sidebarSize", brand.data.ui.layout.sidebar.defaultWidth);
  }
});
</script>

<style scoped lang="scss"></style>
