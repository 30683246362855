<template>
  <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="form"
      :label-width="labelWidth"
      :style="style"
      :disabled="isDisabled === 'false'? false:true">
    <el-form-item label="이름" prop="name">
      <el-input v-model="form.name"/>
    </el-form-item>
    <el-form-item label="직급" prop="position">
      <el-select v-model="form.position" placeholder="please select Position">
        <el-option v-for="position of positionArray" :label="position" :value="position"/>
      </el-select>
    </el-form-item>
    <el-form-item label="업무장소" prop="place">
      <el-select v-model="form.place" placeholder="please select Zone type">
        <el-option label="내부" value="내부"/>
        <el-option label="외부" value="외부"/>
      </el-select>
    </el-form-item>
    <el-form-item label="역할" prop="role">
      <el-checkbox-group v-model="form.role">
        <el-checkbox label="개발" name="개발"/>
        <el-checkbox label="기획" name="기획"/>
        <el-checkbox label="관리" name="관리"/>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="등급" prop="grade">
      <el-select v-model="form.grade" placeholder="please select Grade">
        <el-option v-for="each of grade" :label="each" :value="each"/>
      </el-select>
    </el-form-item>
    <el-form-item label="휴대폰" prop="cell">
      <el-input v-model="form.cell" @keyup="onCell" style="width: 150px"/>
    </el-form-item>
    <el-form-item label="이메일 주소" prop="loginId">
      <el-input :disabled="form.id !== ''" v-model="form.loginId"/>
    </el-form-item>
    <el-form-item label="패스워드" prop="pw">
      <el-input v-model="form.pw" type="password"/>
    </el-form-item>
    <el-form-item label="권한" prop="role">
      <el-checkbox-group v-model="form.auth">
        <el-checkbox v-for="each in authArray" :label="each" :name="each"/>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="순번" prop="ord">
      <el-radio-group v-model="form.ord">
        <el-input-number v-model="form.ord"></el-input-number>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="비고">
      <el-input v-model="form.desc" type="textarea"/>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit(ruleFormRef)">Save</el-button>
      <el-button @click="handleCancel">Cancel</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref} from 'vue'
import { db, auth, createUserWithEmailAndPassword } from "src2/thirdparty/firebase/init";
import {collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc} from "firebase/firestore";

const emit = defineEmits(['afterSave', 'afterCancel']);
const props = defineProps({
  style: String,
  labelWidth: String,
  isDisabled: Boolean,
  data: undefined,
});
const empData = ref(props.data);
const ruleFormRef = ref(null);
const form = reactive({
  id: '',
  name: '',
  position: '',
  place: '',
  role: [],
  auth: [],
  ord: 99,
  grade: '',
  desc: '',
  cell: '',
  delYn: 'N',
  loginId: '',
  pw: '',
});

const rules = reactive({
  name: [
    {required: true, message: 'Please input a name', trigger: 'blur'},
    {min: 2, max: 10, message: 'Length should be 3 to 10', trigger: 'blur'}
  ],
  loginId: [
    {required: true, message: 'Please input an email address', trigger: 'blur'},
    {min: 8, max: 30, message: 'Length should be 8 to 30', trigger: 'blur'}
  ],
  pw: [
    {required: true, message: 'Please input a password', trigger: 'blur'},
    {min: 5, max: 20, message: 'Length should be 5 to 20', trigger: 'blur'}
  ],
  grade: [
    {required: true, message: 'Please select a grade', trigger: 'blur'}
  ],
  role: [
    {required: true, message: 'Please choose roles', trigger: 'blur'}
  ],
  position: [
    {required: true, message: 'Please select a position', trigger: 'blur'}
  ],
  place: [
    {required: true, message: 'Please select a place where working on', trigger: 'blur'}
  ]
});

onMounted(() => {
  if (empData.value) {
    Object.assign(form, empData.value);
  }
});
const authArray = [
  'admin',
  'manager',
  'middle-manager',
  'user',
];
const positionArray = [
  'CEO',
  'CTO',
  '이사',
  '본부장',
  '부장',
  '차장',
  '과장',
  '대리',
  '주임',
  '사원',
  '연구소장',
  '수석연구원',
  '책임연구원',
  '선임연구원',
  '전임연구원',
  '주임연구원',
  '연구원',
];
const grade = [
  '특급',
  '고급',
  '중급',
  '초급',
  '해당사항 없음',
]
const generateRandomString = (num) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < num; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const onSubmit = async (_ruleFormRef) => {
  await _ruleFormRef.validate((valid) => {
    if (valid) {
      // emit('onSubmit', form);
      try {
        const emp = collection(db, 'emp');
        if (!form.id) { // create
          const uid = generateRandomString(20);
          form.id = uid;
          const document = doc(emp, uid);
          setDoc(document, form).then(() =>{
            createUserWithEmailAndPassword(auth, form.loginId, form.pw).then(
                function(user) {
                  console.log('user', user);
                  // alert('회원가입 완료!');
                  emit('afterSave');
                },
                function(err) {
                  alert('에러 : ' + err.message);
                }
            );
          });
        } else { // update
          form.id = form.id.trim();
          const document = doc(emp, form.id);
          updateDoc(document, form).then(() => {
            emit('afterSave');
          });
        }
      } catch (e) {
        console.log('error : ', e.message);
      } finally {
        // emit('afterSave');
      }
    } else {
    }
  });
};

const handleCancel = () => {
  emit('afterCancel');
}

const onCell = () => {
  form.cell = form.cell.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-")
}
</script>

<style scoped lang="scss">

</style>
