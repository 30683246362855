<template>
  <el-aside style="overflow-x: hidden !important; overflow-y: auto !important;" :width="sidebarSize">
    <DefaultMenu
        class="app-sidebar"
        @update:sidebarSize="handleSidebar"
        defaultActive="1-1"
        mode="vertical"
        default-openeds="[1,2,3]"
    >
      <slot></slot>
    </DefaultMenu>
  </el-aside>
</template>

<script setup>
import DefaultMenu from "@/components/layout/default/DefaultMenu.vue";
import {ref} from "vue";
import { brandStore } from "@/stores/brand.store";

const brand = brandStore();
const sidebarSize = ref(brand.data.ui.layout.sidebar.defaultWidth);

const handleSidebar = (data) => sidebarSize.value = data;

</script>

<style scoped lang="scss">
.app-sidebar {
  height: 100%;
}
</style>
