<template>
  <el-row>
    <el-col :span="(24 - screenSystem) / 2"></el-col>
    <el-col :span="screenSystem">
      <div :style="appStyle">
        <div ref="headerDom">
        <el-header>
          <slot name="header"></slot> <!-- TODO[JP]: 이런식으로 넣고 defaultLayoutView를 사용하는 appLayoutView 생성하여 사용 -->
        </el-header>
        </div>
        <div :class="middleAreaClass">
          <slot name="sideMenu"></slot>
          <div class="is-vertical" ref="appContentDom" :style="contentStyle">
            <el-main style="height: 100%;">
              <DefaultNav>
                <router-view></router-view>
              </DefaultNav>
            </el-main>
          </div>
        </div>
        <div ref="footerDom">
          <el-footer>
            <slot name="footer"></slot>
          </el-footer>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watchEffect } from "vue";
import { brandStore } from "@/stores/brand.store";

const brand = brandStore();
const { proxy } = getCurrentInstance();
const appStyle = ref();
const contentStyle = ref();
const screenSystem = ref();

const middleAreaClass = ref();
const middleAreaStyle = ref();
const headerDom = ref(null);
const appContentDom = ref(null);
const footerDom = ref(null);

onMounted(() => {
  const env = ref(brand.data.ui.layout.frameScroll);
  const screenWidth = ref(brand.data.ui.layout.screenWidth);

  let appContainerHeightOnStyle = '';
  if (env.value === "ALL") {
    appStyle.value = "overflow-y: auto; height: 100vh;";
    contentStyle.value = "overflow-y: hidden;";
    appContainerHeightOnStyle = '';
  } else if (env === "CONTENT") {
    appStyle.value = "overflow-y: hidden;";
    contentStyle.value = "overflow-y: auto;";
    appContainerHeightOnStyle = `height: calc(100vh - ${headerDom.value.offsetHeight}px - ${footerDom.value.offsetHeight}px);`; //  - 35px
  }
  if(screenWidth.value === 'FULL') {
    middleAreaClass.value = 'middleAreaAlignCenter';
    middleAreaStyle.value = 'width: 100vw;';
    // contentStyle.value += "width: 100vw;";
  } else {
    middleAreaClass.value = 'middleAreaAlignCenter';
    middleAreaStyle.value = `width: ${screenWidth.value};`;
  }
  contentStyle.value = contentStyle.value + middleAreaStyle.value + appContainerHeightOnStyle;
});

const widthEnv = brand.data.ui.layout.screenDefaultWidth;
if (widthEnv !== null) {
  screenSystem.value = widthEnv;
}

watchEffect(() => {
  proxy.$router.currentRoute.value.path; // watching
  screenSystem.value = brand.data.ui.layout.screenSystem;
});

</script>

<style scoped lang="scss">
#app {
  height: 100vh;
}

.app-container {
  // height: 100vh;
  // height: calc(100vh - var(--footer-height) - 106px);
}

.middleAreaAlignCenter {
  display: flex;
  justify-content: center;
}

.el {
  &-main {
    padding: 0px 10px;
  }
}
.is-vertical {
  overflow-y: auto;
  // padding-bottom: 50px;
  height: calc(100vh - 120px);
  width: calc(100vw - 180px) !important;
  @media (max-width: 768px) {
    width: calc(100vw - 63px) !important;
  }
}
</style>
