import axios from "axios";
import router from "@/router";


export const axiosInterceptor = {
  regist() {
    axios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        console.log(error);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("auths");
          router.push("/error");
        }
        return Promise.reject(error);
      }
    );
  },
};
