import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const loginStore = defineStore("loginStore", () => {
    const o = ref({});

    /*function set(param) {
        data.value = param;
    }*/
    const data = computed(() => o.value);
    const set = (p) => {o.value = p;}
    return { o, set, data };
});
