import axiosHandler from "@/utils/axios.handler";
import brand from "@/brand";

const STATIS_URL = `${brand.backend}`;

class UseMtrlsService {
  getList(params) {
    return axiosHandler
      .getInstance()
      .get(
        `${STATIS_URL}statis/_mtrls`,
        { params: params.request },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        }
      )
      .then((res) => {
        console.log("res.data : ", res.data);

        params.success({
          rowData: res.data.data,
          rowCount: res.data.lastRow,
        });
        return { res, params };
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }

  getOrganicList(params) {
    return axiosHandler
      .getInstance()
      .get(
        `${STATIS_URL}statis/_organic`,
        { params: params.request },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        }
      )
      .then((res) => {
        console.log("res.data ::::: ");
        console.log(res.data);
        let tot = 0;

        res.data.data.forEach((each) => {
          tot += Number(each.cnt);
        });

        res.data.data[0].tot = tot;
        res.data.data[0].cnt = res.data.lastRow;
        console.log(res.data.data[0]);
        params.success({
          rowData: res.data.data,
          rowCount: 1,
        });
        return { res, params };
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }

  getExuseList(params) {
    return axiosHandler
      .getInstance()
      .get(
        `${STATIS_URL}statis/_mtrlsExuse`,
        { params: params.request },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        }
      )
      .then((res) => {
        console.log("res.data ::::: ");
        console.log(res.data);

        params.success({
          rowData: res.data.data,
          rowCount: res.data.lastRow,
        });
        return { res, params };
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }

  getBanList() {
    return axiosHandler
      .getInstance()
      .get(`${STATIS_URL}statis/_mtrlsBan`, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }
}

export default new UseMtrlsService();
