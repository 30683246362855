<template>
  <el-table
      v-if="isDataLoaded"
      highlight-current-row
      :data="emps"
      style="width: 100%"
      table-layout="auto"
  >
    <el-table-column type="index" width="50"/>
    <el-table-column prop="name" label="이름" width="200"/>
    <el-table-column prop="position" label="직급" width="100"/>

    <el-table-column :label="year" v-for="year in [Number(targetYear), (Number(targetYear) + 1)]">
      <el-table-column v-for="(month, idx) of monthData" :prop="month" :label="month" width="40">
        <template #default="prop">
          <div :style="prop.row[Number(year)][Number(month)]?'background-color: #2ea371':''">&nbsp;</div>
<!--          -->
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref} from 'vue'
import EmployeeService from "src2/services/employee.service.js";
import {collection, getDocs} from "firebase/firestore";
import { db } from "src2/thirdparty/firebase/init";

const list = ref([]);
const targetYear = ref('');
const emps = ref([]);
const projects = ref([]);
const isDataLoaded = ref(Boolean);
const monthData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

onMounted(async () => {
  isDataLoaded.value = false;
  targetYear.value = new Date().getFullYear();
  emps.value = await EmployeeService.getListWithExceptManager();
  loadProjects().then(() => {
    emps.value.forEach(r => {
      const year_1 = Number(targetYear.value);
      const year_2 = Number(targetYear.value) + 1;
      r[year_1] = [];
      r[year_2] = [];
      monthData.forEach(m => {
        r[year_1][m] = false;
        r[year_2][m] = false;
        for(let i=0; i<projects.value.length; i++) {
          let each = projects.value[i].members;
          if(each[year_1] !== undefined && each[year_1][m] !== undefined && each[year_1][m].includes(r.id)) {
            r[year_1][m] = true;
          }
          if(each[year_2] !== undefined && each[year_2][m] !== undefined && each[year_2][m].includes(r.id)) {
            r[year_2][m] = true;
          }
        }
      });
      return r;
    });
    isDataLoaded.value = true;
    console.log('ddw22', emps.value);
  });

});

const loadProjects = async () => {
  const projectCollection = collection(db, 'project');
  const ret = await getDocs(projectCollection);
  projects.value = [];
  ret.docs.map((r, idx) => {
    const target = r.data();
    target.no = idx + 1;
    projects.value.push(target);
  });
}

</script>

<style scoped lang="scss">

</style>
