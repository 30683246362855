import {createWebHistory, createRouter} from "vue-router";
import AgGridView from "@/views/sample/AgGridView.vue";
import MainView from "@/views/sample/MainView.vue";
import ElementPlusView from "@/views/sample/ElementPlusView.vue";
import GojsView from "@/views/sample/GojsView.vue";
import SettingView from "@/views/sample/SettingView.vue";
import DefaultLayoutView from "@/views/layout/default/DefaultLayoutView.vue";
import {brandStore} from "@/stores/brand.store";

// cis
import CisMainView from "@/views/kcii/cis/main/MainView.vue";
import Intro from "@/views/kcii/cis/Intro.vue";
//admin
import AdminLayout from "@/views/kcii/cis/layout/AdminLayoutView.vue";
// admin/stats
import ConnectStats from "@/views/kcii/cis/stats/ConnectStatsView.vue";
import SearchRankStats from "@/views/kcii/cis/stats/SearchRankStatsView.vue";
import YearStats from "@/views/kcii/cis/stats/YearStatsView.vue";
import SchRegulationStats from "@/views/kcii/cis/stats/SchRegulationsStatsView.vue";
import UseMtrlsStats from "@/views/kcii/cis/stats/UseMtrlsStatsView.vue";
import MtrlsStats from "@/views/kcii/cis/stats/MtrlsStatsView.vue";

import CestoLayout from "src2/views/layout/CestoLayout.vue";
import ProjectChartView from "src2/views/pms/ProjectChartView.vue";
import MpChartView from "src2/views/pms/MpChartView.vue";
import EmployeeListView from "src2/views/EmployeeListView.vue";
import EmployeeView from "src2/views/EmployeeView.vue";
import ProjectView from "src2/views/ProjectView.vue";
import PmsMain from "src2/views/pms/PmsMain.vue";
import DesignLinkView from "src2/views/designLink/DesignLinkView.vue"
import EmployeeMainView from "src2/views/EmployeeMainView.vue";
import LoginView from "src2/views/login/LoginView.vue";

import DevToolView from "src2/views/devtool/DevToolView.vue";
import SnippetView from "src2/views/snippet/SnippetView.vue";

const routes = [
    {
        path: "",
        redirect: "/devtool/snippet",
    },
    {
        path: "/login",
        name: "login",
        component: LoginView
    },
    {
        path: "",
        name: "main",
        component: CestoLayout,
        children: [
            {
                path: "/devtool",
                name: "Dev Tools",
                component: DevToolView,
                children: [
                    {
                        path: "snippet",
                        name: "Code Snippet",
                        component: SnippetView
                    }
                ]
            },
            {
                path: "/designLink",
                name: "Link for Design Support",
                component: DesignLinkView,
            },
            {
                path: "/pms",
                name: "Project Management",
                component: PmsMain,
                children: [
                    {
                        path: "prjWithMpChart",
                        name: "투입현황",
                        component: ProjectChartView
                    },
                    {
                        path: "project/:id",
                        name: "projectDetail",
                        component: ProjectView
                    },
                    {
                        path: "project/create",
                        name: "New Project",
                        component: ProjectView
                    },
                    {
                        path: "MpChart",
                        name: "투입인력",
                        component: MpChartView
                    },
                ]
            },
            {
                path: "/emp",
                name: "Employee",
                component: EmployeeMainView,
                children: [
                    {
                        path: "list",
                        name: "직원목록",
                        component: EmployeeListView
                    },
                    {
                        path: "create",
                        name: "직원 신규등록",
                        component: EmployeeView
                    },
                    {
                        path: "modify/:id",
                        name: "직원정보 수정",
                        component: EmployeeView
                    },
                ]
            },
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
