import axiosHandler from "@/utils/axios.handler";
import brand from "@/brand";

const STATIS_URL = `${brand.backend}`;

class StatisService {
  getList(params) {
    return axiosHandler
      .getInstance()
      .get(
        `${STATIS_URL}statis/_connect`,
        { params: params.request },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        }
      )
      .then((res) => {
        console.log("res.data : ", res.data);
        let number = 0;
        res.data.data.map((obj) => {
          obj.rn = res.data.lastRow - number++;
        });
        params.success({
          rowData: res.data.data,
          rowCount: res.data.lastRow,
        });
        return params;
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }

  getDetail(params) {
    return axiosHandler
      .getInstance()
      .get(
        `${STATIS_URL}cis/log/_userLog`,
        { params: params.request },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        }
      )
      .then((res) => {
        let number = 0;
        res.data.data.map((obj) => {
          obj.rn = res.data.lastRow - number++;
        });
        params.success({
          rowData: res.data.data,
          rowCount: res.data.lastRow,
        });
        return { res, params };
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }
}

export default new StatisService();
