<template>
  <Employee
      v-if="data"
      style="max-width: 600px"
      label-width="140px"
      is-disabled="false"
      :data="data"
      @afterSave="handleSaveEmp"
      @afterCancel="handleCancelEmp"
  ></Employee>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref, getCurrentInstance} from 'vue'
import Employee from "@/apps/cesto/components/employee/Employee.vue";
import { db } from "src2/thirdparty/firebase/init";
import {collection, doc, getDoc} from "firebase/firestore";

const data = ref(null);
const {proxy} = getCurrentInstance();

onMounted(async () => {
  console.log('proxy.$route.params', proxy.$route);
  let id = proxy.$route.params.id;
  if (id) {
    id = id.trim();
    const empCollection = collection(db, 'emp');
    const document = await doc(empCollection, id);
    const d = await getDoc(document);
    data.value = d.data();
  } else {
    data.value = {};
  }
});

const handleSaveEmp = () => {
  proxy.$router.push('/emp/list');
}

const handleCancelEmp = () => {
  proxy.$router.push('/emp/list');
}
</script>

<style scoped lang="scss">

</style>
