<template>
  <DefaultLayout>
    <template v-slot:header>
      <CestoHeader></CestoHeader>
    </template>
    <template v-slot:sideMenu>
      <DefaultSideMenu>
        <el-sub-menu index="1">
          <template #title>
            <el-icon><Suitcase /></el-icon>
            <span>Dev Tools</span>
          </template>
          <router-link to="/devtool/snippet">
            <el-menu-item index="1-1"><el-icon><Film /></el-icon>Code Snippet</el-menu-item>
          </router-link>
        </el-sub-menu>

        <el-sub-menu index="2">
          <template #title>
            <el-icon><MessageBox /></el-icon>
            <span>유용한 링크</span>
          </template>
          <router-link to="/designLink">
            <el-menu-item index="2-1"><el-icon><PictureFilled /></el-icon>Design</el-menu-item>
          </router-link>
<!--          <a @click="open('https://backlog.com/git-tutorial/kr/')">
            <el-menu-item index="2-2"><el-icon><PictureFilled /></el-icon>Git Tutorial</el-menu-item>
          </a>-->
        </el-sub-menu>

        <el-sub-menu index="3">
          <template #title>
            <el-icon><DataLine /></el-icon>
            <span>프로젝트 관리</span>
          </template>
          <router-link to="/pms/prjWithMpChart"><el-menu-item index="3-1">투입현황</el-menu-item></router-link>
          <router-link to="/pms/mpChart"><el-menu-item index="3-2">투입인력</el-menu-item></router-link>
          <router-link to="/emp/list"><el-menu-item index="3-3">직원관리</el-menu-item></router-link>
        </el-sub-menu>
      </DefaultSideMenu>
    </template>
    <template v-slot:footer>
      <CestoFooter></CestoFooter>
    </template>
  </DefaultLayout>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, provide, watchEffect } from "vue";
import { brandStore } from "@/stores/brand.store";
import DefaultLayout from "@/components/layout/default/DefaultLayout.vue";
import DefaultSideMenu from "@/components/layout/default/DefaultSideMenu.vue";
import CisHeaderView from "@/components/kcii/cis/CisHeader.vue";
import CisFooter from "@/components/kcii/cis/CisFooter.vue";
import CestoFooter from "@/apps/cesto/components/layout/CestoFooter.vue";
import CestoHeader from "@/apps/cesto/components/layout/CestoHeader.vue";

const brand = brandStore();
const { proxy } = getCurrentInstance();

const open =(link) => {
  window.open(link, '_blank');
}
</script>

<style scoped lang="scss">
</style>
