<template>
  <div class="middle-box text-center loginscreen animated fadeInDown">
    <div>
      <div>
        <h1 class="logo-name">MUTO ;)</h1>
      </div>
      <h3>Welcome to MUTO</h3>
      <p style="font-size: 0.8rem; color: #d06060;">This APP is allowed to HOWONSOFT Employee only and the all information
        in this app is confidential. Do Not share your credential to others.
        <!--Continually expanded and constantly improved Inspinia Admin Them (IN+)-->
      </p>
      <form class="m-t" role="form">
        <div class="form-group">
          <el-input v-model="memberId" placeholder="Please input your id in."></el-input>
        </div>
        <div class="form-group">
          <el-input v-model="memberPw" @keyup="handleKeyupOnPassword($event)" type="password" placeholder="Please input your password in."></el-input>
        </div>
        <p v-if="isLoginFailed" class="text-muted text-center" style="padding-bottom: 10px;">
          <small style="color: red;">Login Failed. Please input correct ID and Password.</small>
        </p>

        <el-button @click="handleLogin" style="width: 100%;" type="primary">Login</el-button>

        <p class="text-muted text-center"><small>Do not have an account? Please contact Administrator</small></p>
      </form>
      <p class="m-t" style="font-size: 0.6rem;"><small>COPYRIGHT ⓒ HOWONSOFT ALL RIGHT RESERVED.</small></p>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, ref, getCurrentInstance} from 'vue'
import { db, auth, signInWithEmailAndPassword } from "src2/thirdparty/firebase/init";
import {collection, query, doc, getDoc, where, getDocs, setDoc, updateDoc} from "firebase/firestore";
import { loginStore } from '@/stores/login.store';

const {proxy} = getCurrentInstance();
const memberId = ref();
const memberPw = ref();
const isLoginFailed = ref(false);
const loginMember = ref(null);
const login = loginStore();

onMounted(() => {
  document.querySelector('#app').style = 'height: 100vh;';
});

const handleLogin = async () => {
  const empCollection = collection(db, 'emp');
  const q = query(where("loginId", "==", memberId.value));
  const q2 = query(where('pw', '==', memberPw.value));
  const ret = await getDocs(empCollection, q, q2);
  let flag = false;
  ret.docs.map(r => {
    if(r.data().loginId === memberId.value && r.data().pw === memberPw.value){ // 위 쿼리가 먹지않아 임시처리..
      loginMember.value = r.data();
      flag = true;
    }
  });
  if(flag) {
    login.set(loginMember.value);
    console.log('login.data', login.data.value);
    signInWithEmailAndPassword(auth, memberId.value, memberPw.value)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log('success', user);
          proxy.$router.push('/devtool/snippet');
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
        });
  } else {
    isLoginFailed.value = true;
  }
}

const handleKeyupOnPassword = (e) => {
  if(e.key === 'Enter') {
    handleLogin();
  }
}
</script>

<style scoped lang="scss">
#app {
  height: 100vh;
}

.logo-name {
  font-size: 80px;
  color: #d1d1d1;
}

.form-group {
  margin-bottom: 10px;
}

.loginscreen.middle-box {
  width: 400px;
}

.middle-box {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
}

.text-center {
  text-align: center !important;
}

.m-t {
  margin-top: 15px;
}

.form-control, .single-line {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

.form-control {
  font-size: 0.9rem;
}

.full-width {
  width: 100% !important;
}
</style>
