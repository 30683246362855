<template>
  <div v-if="isNavShow" style="margin-bottom: 20px;" :style="navStyle">
    <el-page-header>
      <template #content>
        <div class="flex items-center">
          <span class="header-title"> {{ pageTitle }}</span>
        </div>
      </template>
      <template #extra>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>
            <router-link :to="{ path: '/' }"> <el-icon :size="16"><HomeFilled /></el-icon></router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="padding-top: 2px;" v-for="item in fullPath" :key="item"> {{ item }}</el-breadcrumb-item>
        </el-breadcrumb>
      </template>
    </el-page-header>
  </div>
  <slot> </slot>
</template>

<script setup>
import {getCurrentInstance, ref, onMounted, watch, watchEffect, reactive} from "vue";
import { ElNotification as notify } from "element-plus";
import {brandStore} from "@/stores/brand.store";

const { proxy } = getCurrentInstance();
const pageTitle = ref(null);
const fullPath = ref(null);
const path = ref([]);
const brand = brandStore();
const isNavShow = ref(brand.data.ui.layout.nav.show);
const underlineOption = reactive(brand.data.ui.layout.nav.underline);
const navStyle = ref();

onMounted(() => {
  navStyle.value = `padding-bottom: ${underlineOption.paddingBottom};`
  navStyle.value += `border-bottom: ${underlineOption.style} ${underlineOption.color} 1px;`;
});

watchEffect(() => {
  pageTitle.value = proxy.$router.currentRoute.value.name;
  const matched = proxy.$router.currentRoute.value.matched;
  fullPath.value = matched.map(r => r.path === ''?'':r.name).filter(rr => rr !== '' && rr.toLowerCase().indexOf('layout') === -1);

});
</script>

<style scope lang="scss">
.header-title {
  font-size: var(--el-font-size-extra-large);
  font-weight: bold;
  color: #5d6286;
}

.el-page-header {
.el-page-header__back {
display: none;
}
.el-divider {
display: none;
}
}
</style>
