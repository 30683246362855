<template>
  <router-view></router-view>
</template>

<script setup>
import brand from "@/brand";
import { ref, onMounted, getCurrentInstance } from "vue";

onMounted(() => {
  init();
});

const init = () => {
  console.log("APP :: onMounted and called init();");
};
</script>

<style scoped lang="scss">
</style>
