import axiosHandler from "@/utils/axios.handler";
import brand from "@/brand";

const STATIS_URL = `${brand.backend}`;

class UseMtrlsService {
  getList(params) {
    console.log("service params", params);
    return axiosHandler
      .getInstance()
      .get(
        `${STATIS_URL}statis/_useMtrls`,
        { params: params.request },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        }
      )
      .then((res) => {
        console.log("res.data : ", res.data);

        params.success({
          rowData: res.data.data,
          rowCount: res.data.lastRow,
        });
        return { res, params };
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }
}

export default new UseMtrlsService();
