import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router.js";
import App from "./App.vue";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import axios from "axios";
import { axiosInterceptor } from "@/interceptor/axios.interceptor";

/* Styles */
import "element-plus/dist/index.css";
import "./assets/scss/base.scss";
import "highlight.js/styles/monokai.css";
/*-- Styles */

import { brandStore } from "@/stores/brand.store";
import brandjs from "@/brand";
import { loginStore } from "@/stores/login.store";

import { db } from "src2/thirdparty/firebase/init";

export const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.config.globalProperties.$router = router;
app.config.globalProperties.axios = axios;

app.use(createPinia());
app.use(router);
app.use(ElementPlus);
app.use(db);
app.mount("#app");

app.provide("router", router);
const login = loginStore();
const brand = brandStore();
brand.set(brandjs);

axios.defaults.baseURL = brand.data.backend;
axiosInterceptor.regist();

router.beforeEach(function (to, from, next) {
  document.title = brand.data.defaultTitle;

  //console.log(login, login.data);
  if(!login.data?.loginId && to.name !== 'login') {
    console.log('to login page');
    router.push("/login");
  }

  if (to.meta.screenSystem) {
    brand.data.ui.layout.screenSystem = to.meta.screenSystem;
  } else {
    brand.data.ui.layout.screenSystem = brand.data.ui.layout.screenDefaultSystem;
  }

  if (to.meta.screenWidth) {
    brand.data.ui.layout.screenWidth = to.meta.screenWidth;
  } else {
    brand.data.ui.layout.screenWidth = brand.data.ui.layout.screenDefaultWidth;
  }

  console.log('to.meta.frameScroll', to.meta.frameScroll, brand.data.ui.layout.frameDefaultScroll);
  if (to.meta.frameScroll) {
    brand.data.ui.layout.frameScroll = to.meta.frameScroll;
  } else {
    brand.data.ui.layout.frameScroll = brand.data.ui.layout.frameDefaultScroll;
  }
  console.log('done - router.beforeEach')
  next();
});
