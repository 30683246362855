<template>
  <codemirror
      v-model="initCode"
      placeholder="Code goes here..."
      :autofocus="true"
      :indent-with-tab="true"
      :tab-size="2"
      :extensions="extensions"
      theme="default"
      @ready="handleReady"
      @change="handleChange($event)"
      @focus="log('focus', $event)"
      @blur="log('blur', $event)"
  />
</template>

<script setup>
import {defineComponent, reactive, ref, shallowRef, watchEffect} from 'vue'
import { Codemirror } from 'vue-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import { java } from '@codemirror/lang-java'
import { html } from '@codemirror/lang-html'
import { markdown } from '@codemirror/lang-markdown'
import { oneDark } from '@codemirror/theme-one-dark'

const props = defineProps({
  initCode: String,
  type: String
});

const emit = defineEmits(['onChange'])

watchEffect(() => {
  console.log(props.type);
  if(props.type === 'Javascript'){
    extensions.value = [javascript()];
  } else if(props.type === 'JAVA'){
    extensions.value = [java()];
  } else if(props.type === 'HTML'){
    extensions.value = [html()];
  } else if(props.type === 'Markdown'){
    extensions.value = [markdown()];
  }

})

const handleChange = (e) => {
  emit('onChange', e);
}

// const code = ref(`console.log('Hello, world!')`)
const code = ref(props.initCode);
// const extensions = [javascript(), oneDark]
const extensions = ref([javascript()])

// Codemirror EditorView instance ref
const view = shallowRef()
const handleReady = (payload) => {
  view.value = payload.view
}

const log = (message, event) => {
  console.log(message, event);
}

// Status is available at all times via Codemirror EditorView
const getCodemirrorStates = () => {
  const state = view.value.state
  const ranges = state.selection.ranges
  const selected = ranges.reduce((r, range) => r + range.to - range.from, 0)
  const cursor = ranges[0].anchor
  const length = state.doc.length
  const lines = state.doc.lines
// more state info ...
// return ...
}

/*export default defineComponent({
  components: {
    Codemirror
  },
  setup() {
    const code = ref(`console.log('Hello, world!')`)
    const extensions = [java(), oneDark]

    // Codemirror EditorView instance ref
    const view = shallowRef()
    const handleReady = (payload) => {
      view.value = payload.view
    }

    // Status is available at all times via Codemirror EditorView
    const getCodemirrorStates = () => {
      const state = view.value.state
      const ranges = state.selection.ranges
      const selected = ranges.reduce((r, range) => r + range.to - range.from, 0)
      const cursor = ranges[0].anchor
      const length = state.doc.length
      const lines = state.doc.lines
      // more state info ...
      // return ...
    }

    return {
      code,
      extensions,
      handleReady,
      log: console.log
    }
  }
})*/
</script>
