import {db} from "src2/thirdparty/firebase/init";
import {collection, query, doc, getDoc, where, getDocs, setDoc, updateDoc} from "firebase/firestore";

class CodeService {


    constructor() {
    }

    async getTargetCodeList(cd) {
        const codeCollection = collection(db, 'code');
        const q = query(codeCollection, where("cd", "==", cd));
        const ret = await getDocs(codeCollection);
        console.log('??', ret.docs);
        const data = [];
        ret.docs.map(r => {
            data.push(r.data());
        });
        return data.pop();
    }
}
export default new CodeService();
