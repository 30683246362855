<template>
  <footer class="footer">
    <div class="container">
      <div class="copy">
        <p class="fl">
          <span class="font_14">HOWONSOFT</span>
        </p>
        <div class="fr txt_right font_12">
          <p>부산광역시 동래구 미남로132번길 17, 403호 (희산빌딩)</p>
<!--          <p>TEL. 051-714-5115 FAX. 사업자등록번호 : 389-87-00165</p>-->
          <p class="lightgray_c">Copyright 2022(C) HOWONSOFT. ALL RIGHT RESERVED.
            RESERVED.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
</script>

<style scoped type="scss">
.footer {
  height: 70px;
  background: #292929;
  clear: both;
  position: relative;
}

.copy {
  padding: 5px 40px;
  color: #fff;
  clear: both;
  overflow: hidden;
  font-size: 12px;
}
</style>
