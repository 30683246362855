import {db} from "src2/thirdparty/firebase/init";
import {collection, query, doc, getDoc, where, getDocs, setDoc, updateDoc} from "firebase/firestore";

class EmployeeService {


  constructor() {
  }
  async delete(id) {
    const empCollection = collection(db, 'emp');
    const document = doc(empCollection, id);
    let targetData = await getDoc(document);
    const bb = targetData.data();
    bb.delYn = 'Y';
    return updateDoc(document, bb);

    //return deleteDoc(document);
  }

  async getList() {
    const empCollection = collection(db, 'emp');
    const q = query(where("delYn", "==", 'N'));
    const ret = await getDocs(empCollection, q);
    console.log(ret);
    const data = [];
    ret.docs.map(r => {
      if(r.data().delYn === 'N'){ // 위 쿼리가 먹지않아 임시처리..
        data.push(r.data());
      }

    });
    return data;
  }

  async getList() {
    const empCollection = collection(db, 'emp');
    const q = query(empCollection, where("delYn", "==", 'N'));
    const ret = await getDocs(q);
    const data = [];
    ret.docs.map(r => {
      data.push(r.data());
    });
    return data;
  }

  async getListWithExceptManager() {
    const empCollection = collection(db, 'emp');
    const q = query(empCollection, where("role", "array-contains-any", ['개발', '기획']));
    const ret = await getDocs(q);
    const data = [];
    ret.docs.map(r => {
      data.push(r.data());
    });
    return data;
  }

}
export default new EmployeeService();
