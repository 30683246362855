import axiosHandler from "@/utils/axios.handler";
import brand from "@/brand";

const STATIS_URL = `${brand.backend}`;

class SearchRankStatisService {
  getList(params) {
    return axiosHandler
      .getInstance()
      .get(
        `${STATIS_URL}statis/_search`,
        { params: params.request },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        }
      )
      .then((res) => {
        console.log("res.data : ", res.data);

        res.data.data.map((obj) => {
          obj.top1 = obj.rnk1 != "" ? obj.rnk1 + "(" + obj.cnt1 + ")" : "-";
          obj.top2 = obj.rnk2 != "" ? obj.rnk2 + "(" + obj.cnt2 + ")" : "-";
          obj.top3 = obj.rnk3 != "" ? obj.rnk3 + "(" + obj.cnt3 + ")" : "-";
          obj.top4 = obj.rnk4 != "" ? obj.rnk4 + "(" + obj.cnt4 + ")" : "-";
          obj.top5 = obj.rnk5 != "" ? obj.rnk5 + "(" + obj.cnt5 + ")" : "-";
        });

        params.success({
          rowData: res.data.data,
          rowCount: res.data.lastRow,
        });
        return params;
      })
      .catch((e) => {
        console.log(e);
        return e.res;
      });
  }
}

export default new SearchRankStatisService();
