<template>
  <el-table
      highlight-current-row
      :data="list"
      style="width: 100%"
      table-layout="auto"
  >
    <el-table-column type="index" width="50"/>
    <el-table-column prop="name" label="이름" width="200"/>
    <el-table-column prop="position" label="직급" width="100"/>
    <el-table-column prop="place" label="근무장소" width="100"/>
    <el-table-column prop="grade" label="등급" width="100"/>
    <el-table-column prop="role" label="역할" width=""/>
    <el-table-column prop="cell" label="연락처" width="250"/>
    <el-table-column fixed="right" label="Operations" width="120">
      <template #default="prop">
        <el-popconfirm
            confirm-button-text="Yes"
            cancel-button-text="No"
            :icon="InfoFilled"
            icon-color="#626AEF"
            title="Are you sure to delete this?"
            @confirm="handleDelete(prop.row.id)"
        >
          <template #reference>
            <el-button :disabled="!updateable" link type="primary" size="small">Delete</el-button>
          </template>
        </el-popconfirm>
          <el-button :disabled="!updateable" link type="primary" size="small" @click="handleModify(prop.row.id)">Modify</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup>
import {defineEmits, onMounted, ref} from "vue";
import { InfoFilled } from '@element-plus/icons-vue'
import { db } from "src2/thirdparty/firebase/init";
import {collection, doc, getDoc, setDoc, updateDoc} from "firebase/firestore";

const props = defineProps({
  list: [],
  updateable: Boolean
})
const emit = defineEmits(['onDelete', 'onModify'])

onMounted(() => {
});

const handleDelete = (id) => {
  console.log(id);
  emit('onDelete', id);
}
const handleModify = (id) => {
  console.log(id);
  emit('onModify', id);
}
</script>

<style scoped lang="scss">

</style>
